'use client'
import { useEffect, useState, useRef } from 'react'
import type { RefObject } from 'react'

interface IntersectionOptions extends IntersectionObserverInit {
  threshold?: number | number[]
  rootMargin?: string
  triggerOnce?: boolean
  initialInView?: boolean
  skip?: boolean
}

type InViewHookResponse = {
  ref: RefObject<any> | ((node?: Element | null) => void)
  inView: boolean
  entry?: IntersectionObserverEntry
}

export const useInView = (options: IntersectionOptions = {}): InViewHookResponse  => {
  const ref = useRef<Element | null>(null)
  const [inView, setInView] = useState<boolean>(options?.initialInView || false)
  const [entry, setEntry] = useState<IntersectionObserverEntry | undefined>(undefined)

  useEffect(() => {
    if (options.skip || !ref) return

    const observerOptions = {
      threshold: options.threshold || 0,
      rootMargin: options.rootMargin || '0px',
    }

    let observer: IntersectionObserver | undefined

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const [firstEntry] = entries

      setInView(firstEntry.isIntersecting)
      setEntry(firstEntry)
      if (options.triggerOnce && firstEntry.isIntersecting) observer.disconnect()
    }

    if (typeof IntersectionObserver !== 'undefined') {
      observer = new IntersectionObserver(handleIntersection, observerOptions)

      const currentRef = ref.current
      if (currentRef) observer.observe(currentRef)
    } else {
      // Fallback behavior when IntersectionObserver is not available
      console.warn('IntersectionObserver is not supported in this browser. Please consider using a polyfill.')
    }

    return () => { if (observer) observer.disconnect() }
  }, [options])

  return { ref, inView, entry }
}
