import { createContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useParams } from 'next/navigation'
import enDictionary from '../app/dictionaries/en.json'
import { dictionaries } from '@/app/dictionaries'

const getDictionary = async (locale) => dictionaries[locale]()

export const TranslationsContext = createContext<any>({
  dictionary: enDictionary,
})

type Props = {
  children: JSX.Element
}

let prevLang = 'es'
export const TranslationsProvider: FC<Props> = ({ children }) => {
  const { lang } = useParams()
  const [dictionary, setDictionary] = useState(enDictionary)

  useEffect(() => {
    async function updateDictionary(lang) {
      console.log('updateDictionary', lang)
      const incomingDictionary = await getDictionary(lang)
      setDictionary(incomingDictionary)
    }

    if (!dictionary) updateDictionary(lang ? lang + '' : prevLang)
    if (lang !== prevLang) {
      updateDictionary(lang ? lang + '' : prevLang)
      prevLang = lang ? lang + '' : prevLang
    }
  }, [lang, dictionary])

  return (
    <TranslationsContext.Provider value={{ dictionary }}>
      {children}
    </TranslationsContext.Provider>
  )
}
