import { css } from 'styled-components'

type Props = {
  height?:number
  reverse?: boolean
}

export const createUnderline = ({ height = 1, reverse = false }:Props) => css`
  background-image: linear-gradient(var(--color-text), var(--color-text));
  background-position: ${reverse ? '100% 92%' : '0 92%'};
  background-repeat: no-repeat;
  background-size: ${`${reverse ? 100 : 0}% ${height}px`};
  position: relative;
  transition: background-size 300ms ease;

  &:hover,
  &.active {
    background-size: ${`${reverse ? 0 : 100}% ${height}px`};
  }
`

type AlphaStepNumber = 0 | .05 | .1 | .15 | .2 | .25 | .3 | .35 | .4 | .45 | .5 | .55 | .6 | .65 | .7 | .75 | .8 | .85 | .9 | .95 | 1
export function hex2Rgba(hex: string, alpha:AlphaStepNumber) {
  if (!/^#[0-9A-Fa-f]{6}$/i.test(hex)) console.warn('The hexadecimal color provider is not valid')
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const rgbAlpha = (color:string, opacity:AlphaStepNumber) => css`${`color-mix(in srgb, ${`var(${color})`} ${opacity*100}%, transparent);`}`

export const hideScrollbar = () => css`
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }
`

const defaultPlaceHolderStyles = (color) => css<any>`
  transition: opacity 300ms ${({ ease }) => ease};
  color: ${color ?? 'rgba(0, 0, 0, 0)'};
`

export const parsePlaceHolder = (styles, color = null) => (css`
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }

  &::-moz-placeholder { /* Firefox 19+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  &:-ms-input-placeholder { /* IE 10+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }

  &:-moz-placeholder { /* Firefox 18- */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
`)
