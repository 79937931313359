'use client'
import { useContext } from 'react'
import { TranslationsContext } from '@/context'
// import type { TypeTranslationsJsonKeys } from '@/context'

export function useTranslator() {
  const { dictionary } = useContext(TranslationsContext)

  function t(keyValue:string):string | number | boolean | (string|number|boolean)[] {
    if(!keyValue) return null
    if(!dictionary) {
      console.warn('Loading dictionary')
      return null
    }

    const translation = `${keyValue}`.split('.').reduce((p,c) => p&&p[c] || null, dictionary)

    if (!translation) {
      console.warn(`Translation key: '${keyValue}' not found.`)
      return null
    }

    if(typeof translation === 'string' || typeof translation === 'number' || Array.isArray(translation) || typeof translation === 'boolean') {
      return translation
    } else {
      console.warn(`Translation for key: '${keyValue}' has a NestedObj, please specify an inner key`)
      return ''
    }
  }

  return { t }
}
