import { css } from 'styled-components'
import { mq } from '.'

const rem = {
  baseMobile: 15,
  base: 18,
  get: (value:number, factor = null) => `${value / (factor ?? rem.baseMobile)}rem`
}

export const font15_20 = (mobile: boolean = true, weight: 700 = 700) => css`
  font-family: var(--font-basier-circle);
  font-size: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(20, rem[mobile ? 'baseMobile' : 'base'])};
  letter-spacing: -${mobile ? .4 : .5}px;
`

export const font18_22 = (mobile: boolean = true) => css`
  font-family: var(--font-basier-circle);
  font-size: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${mobile ? 500 : 700};
  line-height: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base']) };
  letter-spacing: ${mobile ? '-0.3px' : 0};
`

export const font20_24 = (mobile: boolean = true, weight: 500 | 700 = 500) => css`
  font-size: ${rem.get(20, rem[mobile ? 'baseMobile' : 'base']) };
  font-weight: ${weight};
  line-height: ${rem.get(24, rem[mobile ? 'baseMobile' : 'base']) };
  letter-spacing: -.2px;
`

export const font26_24 = (mobile: boolean = true, weight: 500 | 700 = 500) => css`
  font-size: ${rem.get(26, rem[mobile ? 'baseMobile' : 'base']) };
  font-weight: ${weight};
  line-height: ${rem.get(24, rem[mobile ? 'baseMobile' : 'base']) };
  letter-spacing: -.2px;
`

export const font30_42 = (mobile: boolean = true, weight: 500 | 700 = 700) => css`
  font-size: ${rem.get(30, rem[mobile ? 'baseMobile' : 'base']) };
  font-weight: ${weight};
  line-height: ${rem.get(42, rem[mobile ? 'baseMobile' : 'base']) };
  letter-spacing: -.3px;
`

export const font72_60 = (mobile: boolean = true, weight: 500 | 700 = 700) => css`
  font-size: ${rem.get(72, rem[mobile ? 'baseMobile' : 'base']) };
  font-weight: ${weight};
  line-height: ${rem.get(60, rem[mobile ? 'baseMobile' : 'base']) };
  letter-spacing: -.18px;
  font-family: var(--font-manic);
`

export const font110_98 = (mobile: boolean = true, weight: 500 | 700 = 700) => css`
  font-size: ${rem.get(110, rem[mobile ? 'baseMobile' : 'base']) };
  font-weight: ${weight};
  line-height: ${rem.get(98, rem[mobile ? 'baseMobile' : 'base']) };
  letter-spacing: -.3px;
  font-family: var(--font-manic);
`

export const getP18InputLabel = (mobile: boolean = true, weight: 400 = 400) => css`
  font-size: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: normal;
  letter-spacing: -.3px;
`

export const getP18InputHasContentLabel = (mobile: boolean = true, weight: 400 = 400) => css`
  font-size: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: normal;
  letter-spacing: -.3px;
`



export const getP26_1 = () => {
  return css`
    font-size: ${rem.get(16, rem.baseMobile)};
    font-weight: regular;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: -0.8px;
    font-family: var(--font-basier-circle);

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(26, rem.base)};
      font-weight: 500;
      line-height: ${rem.get(29, rem.base)};
      letter-spacing: -0.3px;
    }
  `
}

export const getP53 = () => {
  return css`
    font-size: ${rem.get(27, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(29, rem.baseMobile)};
    letter-spacing: 0.56px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(53, rem.base)};
      line-height: ${rem.get(57, rem.base)};
      letter-spacing: 1.1px;
    }
  `
}

export const getP20_Mobile = () => {
  return css`
    font-size: ${rem.get(20, rem.baseMobile)};
    font-weight: 600;
    line-height: ${rem.get(25, rem.baseMobile)};
    letter-spacing: -0.23px;
  `
}

export const getP15_1 = () => {
  return css`
    font-size: ${rem.get(12, rem.baseMobile)};
    font-weight: 500;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: -0.4px;
    font-family: var(--font-basier-circle);

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: -0.5px;
    }
  `
}


export const getP15_3 = () => {
  return css`
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 500;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: -.38px;
    font-family: var(--font-basier-circle);

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: .17px;
    }
  `
}

export const getP20_1 = () => {
  return css`
    font-size: ${rem.get(18)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -.36px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(26, rem.base)};
      letter-spacing: -.5px;
    }
  `
}

export const getP114 = () => {
  return css`
    font-size: ${rem.get(72, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(59, rem.baseMobile)};
    letter-spacing: -0.18px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(114, rem.base)};
      line-height: ${rem.get(95, rem.base)};
      letter-spacing: -0.29px;
    }
  `
}

export const getP93_1 = () => {
  return css`
    font-size: ${rem.get(45, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(45, rem.baseMobile)};
    letter-spacing: 1.45px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(93, rem.base)}   ;
      line-height: ${rem.get(93, rem.base)};
      letter-spacing: 3px;
    }
  `
}

export const getP10 = () => {
  return css`
    font-size: ${rem.get(10, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: .25px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(10, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: .25px;
    }
  `
}

export const getP53_2 = () => { // new
  return css`
    font-size: ${rem.get(27, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(29, rem.baseMobile)};
    letter-spacing: 0.56px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(53, rem.base)};
      line-height: ${rem.get(57, rem.base)};
      letter-spacing: 1.1px;
    }
  `
}
